.root {
    padding: calc(var(--mantine-spacing-xl) * 1.5);
  }
  
  .value {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
  }
  
  .diff {
    line-height: 1;
    display: flex;
    align-items: center;
  }
  
  .icon {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
  }
  
  .title {
    font-weight: 700;
    text-transform: uppercase;
  }
  .progressLabel {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    line-height: 1;
    font-size: var(--mantine-font-size-sm);
  }
  
  .stat {
    border-bottom: 3px solid;
    padding-bottom: 5px;
  }
  
  .statCount {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    line-height: 1.3;
  }
  
  .diff {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    display: flex;
    align-items: center;
  }
  
  .icon {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
  }