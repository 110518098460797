@import '../node_modules/@syncfusion/ej2-base/styles/fluent2.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/fluent2.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/fluent2.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/fluent2.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/fluent2.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/fluent2.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/fluent2.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/fluent2.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/fluent2.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/fluent2.css";
@import "../node_modules/@syncfusion/ej2-querybuilder/styles/fluent2.css";
@import '../node_modules/@syncfusion/ej2-notifications/styles/fluent2.css';
@import "../node_modules/@syncfusion/ej2-icons/styles/fluent2.css";
/* @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-querybuilder/styles/material.css"; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.e-vscroll .e-box{
  padding: 0 2px !important;
}
.e-vscroll:not(.e-scroll-device) {
  padding: 0 2px !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footerf {
  /* margin-top: rem(120px); */
  /* display: flex; */
  /* justify-content: end; */
  /* bottom: 0; */
  /* right:2; */
  /* justify-content: space-evenly; */
  /* position: sticky; */
  border: 0.3px solid;
  /* width: 100%; */
  background-color: #fff;
  /* padding: 8px; */
  /* z-index: 999; */
}

.innerf {
  display: flex;
  /* margin-left: 15%; */
  /* justify-content: end; */
  /* align-items: center; */
  /* padding-top: var(--mantine-spacing-xl); */
  /* padding-bottom: var(--mantine-spacing-xl); */

  @media (max-width: $mantine-breakpoint-xs) {
    flex-direction: column;
  }
}

.linksf {
  @media (max-width: $mantine-breakpoint-xs) {
    margin-top: var(--mantine-spacing-md);
  }
}

.contextmenu {
  .menu:hover{
    background-color: #282c34;
    color: azure;
  }
}
.context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius:5;
  opacity: 0;
  /* transform: scale(0.9); */
  transition: opacity 0.3s ease-in, transform 0.3s ease-out;
}

.context-menu.visible {
  opacity: 1;
  transform: scale(1);
}

.context-menu-item {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.context-menu-item:last-child {
  border-bottom: none;
}

.context-menu-item:hover {
  background-color: #282c34;
  color: azure;
}

.whitespace{
  white-space: initial;
  /* text-align: right; */
}
.whitespace .left{
  text-align: left;
}
.whitespace .right{
  text-align: right;
}
.boxshadow{
  -webkit-box-shadow: 0 0 12px 2px rgba(0,0,0,0.3);
-moz-box-shadow: 0 0 12px 2px rgba(0,0,0,0.3);
box-shadow: 0 0 12px 2px rgba(0,0,0,0.3);
}
.boxshadow-btn{
  -webkit-box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
}
/* button[aria-label="Show/Hide search"] {
  display: none !important;
} */

/* QueryBuilderComponent dropdown background color */
.e-treeview .e-list-item.e-active > .e-fullrow{
  background: rgba(235, 235, 235, 0.4) !important;
  color: black !important;
}

.e-list-parent .e-ul > .e-list-item :hover{
  background: rgba(235, 235, 235, 0.4) !important;
  color: black !important;
}

/* QueryBuilderComponent dropdown width */
/* .e-filter-input { 
  width: 250px !important;
} */
/* QueryBuilderComponent combobox and dataGrid */
/* .e-rule-value{
  width: 350px !important;
} */